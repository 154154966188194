$(function() { 

		$('.modal-box, .js-open-modal').click(function (e) {
			console.log('test');
		    e.stopPropagation();
		});

		$(document).on('click', function (e) {

		$('body').removeClass('modal');

		$(".modal-box, .modal-overlay").fadeOut(500, function() {
			$(".modal-overlay").remove();
		});
		// Remove the specific class
		$('.modal-box').removeClass('opened');
		// Stop the video from playing
		$('.modal-box').find('video').each(function() {
				$(this).get(0).pause();
		});
		});

	  $(document).ready(function(){
	    $('#viaTherapyVideo').get(0).pause();
	  });

	  $(function(){

		var appendthis =  ("<div class='modal-overlay js-modal-close'></div>");

			$('a[data-modal-id]').click(function(e) {

				$('body').addClass('modal');

				e.preventDefault();
				$("body").append(appendthis);
				$(".modal-overlay").fadeTo(500, 0.9);
				var modalBox = $(this).attr('data-modal-id');
				$('#'+modalBox).fadeIn($(this).data());
				$('#'+modalBox).addClass('opened');
			    if (($('.modal-box.opened').find('video').attr('autoplay') === 'autoplay')) {
			        $('.modal-box.opened').find('video').get(0).play();
			    }
			});

		$(".js-modal-close, .modal-overlay").click(function(e) {

			e.preventDefault();

			$('body').removeClass('modal');

			$(".modal-box, .modal-overlay").fadeOut(500, function() {
				$(".modal-overlay").remove();
			});
			// Remove the specific class
			$('.modal-box').removeClass('opened');
			// Stop the video from playing
			$('.modal-box').find('video').each(function() {
					$(this).get(0).pause();
			});
		});

	});

	// Navigation (Mobile)
	var menu       = $('.nav-mobile');
	var content    = $('.content-wrapper');

	$('.btn-menu-open, .btn-menu-close').on('click', function() {
		menu.toggleClass('menu-open');
	});

	$('.nav-mobile .menu .link a, .logo').on('click', function() {
		menu.removeClass('menu-open');
	});

	$(document).bind('click', function(e) {
	  	if(!$(e.target).is('.menu, .btn-menu-open')) {
			menu.removeClass('menu-open');
	  	}
	});

	// Testimonials
	var testimonials = $('.testimonials-list');

	testimonials.unslider({
		arrows: false,
		autoplay: true,
		delay: 6000
	});

	var scripts = [
		'http://stephband.info/jquery.event.move/js/jquery.event.move.js',
		'http://stephband.info/jquery.event.swipe/js/jquery.event.swipe.js'
	];

	$.getScript(scripts[0]);

	$.getScript(scripts[1], function() {
		testimonials.unslider('initSwipe');
	});

	// Contact Form
	var overlay = $('.overlay');

	var validator = $('#contact').validate({
        rules: {
            name: {
                required: true,
                minlength: 2
            },
            email: {
                required: true,
                email: true
            },
            message: {
                required: true
            }
        },
        messages: {
            name: {
                required: "This field is required",
                minlength: "your name must consist of at least 2 characters"
            },
            email: {
                required: "This field is required"
            },
            message: {
                required: "This field is required",
                minlength: "thats all? really?"
            }
        },
        submitHandler: function(form) {
            $(form).ajaxSubmit({
                type:"POST",
                data: $(form).serialize(),
                url:"process.php",
                success: function() {
                    $('#contact :input').attr('disabled', 'disabled');
                    $('.form').fadeOut( "slow", function() {
                        $(this).find(':input').attr('disabled', 'disabled');
                        $(this).find('label').css('cursor','default');
                        $('.form-success').fadeIn();
                    });
                },
                error: function() {
                    $('.form').fadeOut( "slow", function() {
                        $('.form-error').fadeIn();
                    });
                }
            });
        }
    });

	$('.link-contact a').on('click', function(e) {
		e.preventDefault();
		overlay.fadeIn('slow');
		// $('body').css('overflow', 'hidden');
	});

	$('.form-close, .button-close').on('click', function(e) {
		e.preventDefault();
		overlay.fadeOut('slow', function() {
			validator.resetForm();
			$('.form-error, .form-success').hide();
			$('.form').show();
		});
		$('body').css('overflow', 'visible');
	});


})